/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Invitation } from './Invitation';
import {
    InvitationFromJSON,
    InvitationFromJSONTyped,
    InvitationToJSON,
} from './Invitation';
import type { Membership } from './Membership';
import {
    MembershipFromJSON,
    MembershipFromJSONTyped,
    MembershipToJSON,
} from './Membership';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    slug?: string;
    /**
     * 
     * @type {Array<Membership>}
     * @memberof Team
     */
    readonly members: Array<Membership>;
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof Team
     */
    readonly invitations: Array<Invitation>;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    readonly dashboardUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    readonly isAdmin: boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof Team
     */
    readonly subscription: Subscription;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    readonly hasActiveSubscription: boolean;
}

/**
 * Check if a given object implements the Team interface.
 */
export function instanceOfTeam(value: object): value is Team {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('members' in value) || value['members'] === undefined) return false;
    if (!('invitations' in value) || value['invitations'] === undefined) return false;
    if (!('dashboardUrl' in value) || value['dashboardUrl'] === undefined) return false;
    if (!('isAdmin' in value) || value['isAdmin'] === undefined) return false;
    if (!('subscription' in value) || value['subscription'] === undefined) return false;
    if (!('hasActiveSubscription' in value) || value['hasActiveSubscription'] === undefined) return false;
    return true;
}

export function TeamFromJSON(json: any): Team {
    return TeamFromJSONTyped(json, false);
}

export function TeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Team {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'members': ((json['members'] as Array<any>).map(MembershipFromJSON)),
        'invitations': ((json['invitations'] as Array<any>).map(InvitationFromJSON)),
        'dashboardUrl': json['dashboard_url'],
        'isAdmin': json['is_admin'],
        'subscription': SubscriptionFromJSON(json['subscription']),
        'hasActiveSubscription': json['has_active_subscription'],
    };
}

export function TeamToJSON(value?: Omit<Team, 'id'|'members'|'invitations'|'dashboard_url'|'is_admin'|'subscription'|'has_active_subscription'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'slug': value['slug'],
    };
}

