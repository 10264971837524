/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanySearchResponse
 */
export interface CompanySearchResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResponse
     */
    corporationName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResponse
     */
    companyUuid: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResponse
     */
    filingType: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResponse
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanySearchResponse
     */
    fileDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanySearchResponse
     */
    documentNumber: string;
}

/**
 * Check if a given object implements the CompanySearchResponse interface.
 */
export function instanceOfCompanySearchResponse(value: object): value is CompanySearchResponse {
    if (!('corporationName' in value) || value['corporationName'] === undefined) return false;
    if (!('companyUuid' in value) || value['companyUuid'] === undefined) return false;
    if (!('filingType' in value) || value['filingType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('fileDate' in value) || value['fileDate'] === undefined) return false;
    if (!('documentNumber' in value) || value['documentNumber'] === undefined) return false;
    return true;
}

export function CompanySearchResponseFromJSON(json: any): CompanySearchResponse {
    return CompanySearchResponseFromJSONTyped(json, false);
}

export function CompanySearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'corporationName': json['corporation_name'],
        'companyUuid': json['company_uuid'],
        'filingType': json['filing_type'],
        'status': json['status'],
        'fileDate': (new Date(json['file_date'])),
        'documentNumber': json['document_number'],
    };
}

export function CompanySearchResponseToJSON(value?: CompanySearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'corporation_name': value['corporationName'],
        'company_uuid': value['companyUuid'],
        'filing_type': value['filingType'],
        'status': value['status'],
        'file_date': ((value['fileDate']).toISOString().substring(0,10)),
        'document_number': value['documentNumber'],
    };
}

