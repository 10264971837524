/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `ein` - EIN
 * * `ssn` - SSN/ITIN
 * * `foreign` - Foreign Identification
 * @export
 * @enum {string}
 */
export enum TaxIdTypeEnum {
    Ein = 'ein',
    Ssn = 'ssn',
    Foreign = 'foreign'
}


export function instanceOfTaxIdTypeEnum(value: any): boolean {
    for (const key in TaxIdTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(TaxIdTypeEnum, key)) {
            if (TaxIdTypeEnum[key as keyof typeof TaxIdTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TaxIdTypeEnumFromJSON(json: any): TaxIdTypeEnum {
    return TaxIdTypeEnumFromJSONTyped(json, false);
}

export function TaxIdTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxIdTypeEnum {
    return json as TaxIdTypeEnum;
}

export function TaxIdTypeEnumToJSON(value?: TaxIdTypeEnum | null): any {
    return value as any;
}

