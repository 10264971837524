/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `initial` - Initial Report
 * * `correction` - Correct Prior Report
 * * `update` - Update Prior Report
 * * `exemption` - Newly Exempt Entity
 * @export
 * @enum {string}
 */
export enum TypeOfFilingEnum {
    Initial = 'initial',
    Correction = 'correction',
    Update = 'update',
    Exemption = 'exemption'
}


export function instanceOfTypeOfFilingEnum(value: any): boolean {
    for (const key in TypeOfFilingEnum) {
        if (Object.prototype.hasOwnProperty.call(TypeOfFilingEnum, key)) {
            if (TypeOfFilingEnum[key as keyof typeof TypeOfFilingEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TypeOfFilingEnumFromJSON(json: any): TypeOfFilingEnum {
    return TypeOfFilingEnumFromJSONTyped(json, false);
}

export function TypeOfFilingEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfFilingEnum {
    return json as TypeOfFilingEnum;
}

export function TypeOfFilingEnumToJSON(value?: TypeOfFilingEnum | null): any {
    return value as any;
}

