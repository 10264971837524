/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckTeamStatusResponse
 */
export interface CheckTeamStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckTeamStatusResponse
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckTeamStatusResponse
     */
    admin: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckTeamStatusResponse
     */
    slug: string | null;
}

/**
 * Check if a given object implements the CheckTeamStatusResponse interface.
 */
export function instanceOfCheckTeamStatusResponse(value: object): value is CheckTeamStatusResponse {
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('admin' in value) || value['admin'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function CheckTeamStatusResponseFromJSON(json: any): CheckTeamStatusResponse {
    return CheckTeamStatusResponseFromJSONTyped(json, false);
}

export function CheckTeamStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckTeamStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'],
        'admin': json['admin'],
        'slug': json['slug'],
    };
}

export function CheckTeamStatusResponseToJSON(value?: CheckTeamStatusResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'active': value['active'],
        'admin': value['admin'],
        'slug': value['slug'],
    };
}

