/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `AL` - Alabama
 * * `AK` - Alaska
 * * `AS` - American Samoa
 * * `AZ` - Arizona
 * * `AR` - Arkansas
 * * `AA` - Armed Forces Americas
 * * `AE` - Armed Forces Europe
 * * `AP` - Armed Forces Pacific
 * * `CA` - California
 * * `CO` - Colorado
 * * `CT` - Connecticut
 * * `DE` - Delaware
 * * `DC` - District of Columbia
 * * `FL` - Florida
 * * `GA` - Georgia
 * * `GU` - Guam
 * * `HI` - Hawaii
 * * `ID` - Idaho
 * * `IL` - Illinois
 * * `IN` - Indiana
 * * `IA` - Iowa
 * * `KS` - Kansas
 * * `KY` - Kentucky
 * * `LA` - Louisiana
 * * `ME` - Maine
 * * `MD` - Maryland
 * * `MA` - Massachusetts
 * * `MI` - Michigan
 * * `MN` - Minnesota
 * * `MS` - Mississippi
 * * `MO` - Missouri
 * * `MT` - Montana
 * * `NE` - Nebraska
 * * `NV` - Nevada
 * * `NH` - New Hampshire
 * * `NJ` - New Jersey
 * * `NM` - New Mexico
 * * `NY` - New York
 * * `NC` - North Carolina
 * * `ND` - North Dakota
 * * `MP` - Northern Mariana Islands
 * * `OH` - Ohio
 * * `OK` - Oklahoma
 * * `OR` - Oregon
 * * `PA` - Pennsylvania
 * * `PR` - Puerto Rico
 * * `RI` - Rhode Island
 * * `SC` - South Carolina
 * * `SD` - South Dakota
 * * `TN` - Tennessee
 * * `TX` - Texas
 * * `UT` - Utah
 * * `VT` - Vermont
 * * `VI` - Virgin Islands
 * * `VA` - Virginia
 * * `WA` - Washington
 * * `WV` - West Virginia
 * * `WI` - Wisconsin
 * * `WY` - Wyoming
 * @export
 * @enum {string}
 */
export enum MailStateEnum {
    Al = 'AL',
    Ak = 'AK',
    As = 'AS',
    Az = 'AZ',
    Ar = 'AR',
    Aa = 'AA',
    Ae = 'AE',
    Ap = 'AP',
    Ca = 'CA',
    Co = 'CO',
    Ct = 'CT',
    De = 'DE',
    Dc = 'DC',
    Fl = 'FL',
    Ga = 'GA',
    Gu = 'GU',
    Hi = 'HI',
    Id = 'ID',
    Il = 'IL',
    In = 'IN',
    Ia = 'IA',
    Ks = 'KS',
    Ky = 'KY',
    La = 'LA',
    Me = 'ME',
    Md = 'MD',
    Ma = 'MA',
    Mi = 'MI',
    Mn = 'MN',
    Ms = 'MS',
    Mo = 'MO',
    Mt = 'MT',
    Ne = 'NE',
    Nv = 'NV',
    Nh = 'NH',
    Nj = 'NJ',
    Nm = 'NM',
    Ny = 'NY',
    Nc = 'NC',
    Nd = 'ND',
    Mp = 'MP',
    Oh = 'OH',
    Ok = 'OK',
    Or = 'OR',
    Pa = 'PA',
    Pr = 'PR',
    Ri = 'RI',
    Sc = 'SC',
    Sd = 'SD',
    Tn = 'TN',
    Tx = 'TX',
    Ut = 'UT',
    Vt = 'VT',
    Vi = 'VI',
    Va = 'VA',
    Wa = 'WA',
    Wv = 'WV',
    Wi = 'WI',
    Wy = 'WY'
}


export function instanceOfMailStateEnum(value: any): boolean {
    for (const key in MailStateEnum) {
        if (Object.prototype.hasOwnProperty.call(MailStateEnum, key)) {
            if (MailStateEnum[key as keyof typeof MailStateEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MailStateEnumFromJSON(json: any): MailStateEnum {
    return MailStateEnumFromJSONTyped(json, false);
}

export function MailStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailStateEnum {
    return json as MailStateEnum;
}

export function MailStateEnumToJSON(value?: MailStateEnum | null): any {
    return value as any;
}

