/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `do_not_remind` - Do Not Remind
 * * `queued_for_reminder` - Queued for Reminder
 * * `sent_1d_reminder` - Sent 1 Day Reminder
 * * `sent_7d_reminder` - Sent 7 Day Reminder
 * * `sent_14d_reminder` - Sent 14 Day Reminder
 * * `sent_30d_reminder` - Sent 30 Day Reminder
 * * `next_reminder_phase` - Next Reminder Phase
 * * `new_filing` - New Filing Alert
 * * `boi_needed` - BOI Data Needed
 * * `annual_to_boi_remind` - Annual to BOI Reminder
 * * `boi_data_series` - BOI Data Series
 * * `error` - Error
 * @export
 * @enum {string}
 */
export enum ReminderStatusEnum {
    DoNotRemind = 'do_not_remind',
    QueuedForReminder = 'queued_for_reminder',
    Sent1dReminder = 'sent_1d_reminder',
    Sent7dReminder = 'sent_7d_reminder',
    Sent14dReminder = 'sent_14d_reminder',
    Sent30dReminder = 'sent_30d_reminder',
    NextReminderPhase = 'next_reminder_phase',
    NewFiling = 'new_filing',
    BoiNeeded = 'boi_needed',
    AnnualToBoiRemind = 'annual_to_boi_remind',
    BoiDataSeries = 'boi_data_series',
    Error = 'error'
}


export function instanceOfReminderStatusEnum(value: any): boolean {
    for (const key in ReminderStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(ReminderStatusEnum, key)) {
            if (ReminderStatusEnum[key as keyof typeof ReminderStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReminderStatusEnumFromJSON(json: any): ReminderStatusEnum {
    return ReminderStatusEnumFromJSONTyped(json, false);
}

export function ReminderStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReminderStatusEnum {
    return json as ReminderStatusEnum;
}

export function ReminderStatusEnumToJSON(value?: ReminderStatusEnum | null): any {
    return value as any;
}

