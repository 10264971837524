/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `DOMP` - Florida For Profit Corporation
 * * `DOMNP` - Florida Non-Profit Corporation
 * * `FORP` - Foreign Profit Corporation
 * * `FORNP` - Foreign Non-Profit Corporation
 * * `DOMLP` - Florida Limited Partnership
 * * `FORLP` - Foreign Limited Partnership
 * * `FLAL` - Florida Limited Liability Company
 * * `FORL` - Foreign Limited Liability Company
 * * `NPREG` - Non-Profit, Registration
 * * `MISC` - Miscellaneous
 * * `NR` - Name Reservation
 * * `TRUST` - Declaration of Trust
 * * `AGENT` - Designation of Registered Agent
 * * `RF` - Rejection Filing
 * * `TM` - Trademark
 * @export
 * @enum {string}
 */
export enum FilingTypeEd2Enum {
    Domp = 'DOMP',
    Domnp = 'DOMNP',
    Forp = 'FORP',
    Fornp = 'FORNP',
    Domlp = 'DOMLP',
    Forlp = 'FORLP',
    Flal = 'FLAL',
    Forl = 'FORL',
    Npreg = 'NPREG',
    Misc = 'MISC',
    Nr = 'NR',
    Trust = 'TRUST',
    Agent = 'AGENT',
    Rf = 'RF',
    Tm = 'TM'
}


export function instanceOfFilingTypeEd2Enum(value: any): boolean {
    for (const key in FilingTypeEd2Enum) {
        if (Object.prototype.hasOwnProperty.call(FilingTypeEd2Enum, key)) {
            if (FilingTypeEd2Enum[key as keyof typeof FilingTypeEd2Enum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FilingTypeEd2EnumFromJSON(json: any): FilingTypeEd2Enum {
    return FilingTypeEd2EnumFromJSONTyped(json, false);
}

export function FilingTypeEd2EnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilingTypeEd2Enum {
    return json as FilingTypeEd2Enum;
}

export function FilingTypeEd2EnumToJSON(value?: FilingTypeEd2Enum | null): any {
    return value as any;
}

