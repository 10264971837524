/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `A` - Active
 * * `I` - Inactive
 * @export
 * @enum {string}
 */
export enum FloridaCorporationStatus {
    A = 'A',
    I = 'I'
}


export function instanceOfFloridaCorporationStatus(value: any): boolean {
    for (const key in FloridaCorporationStatus) {
        if (Object.prototype.hasOwnProperty.call(FloridaCorporationStatus, key)) {
            if (FloridaCorporationStatus[key as keyof typeof FloridaCorporationStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FloridaCorporationStatusFromJSON(json: any): FloridaCorporationStatus {
    return FloridaCorporationStatusFromJSONTyped(json, false);
}

export function FloridaCorporationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloridaCorporationStatus {
    return json as FloridaCorporationStatus;
}

export function FloridaCorporationStatusToJSON(value?: FloridaCorporationStatus | null): any {
    return value as any;
}

