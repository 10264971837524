/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderSummaryLayoutEnum } from './OrderSummaryLayoutEnum';
import {
    OrderSummaryLayoutEnumFromJSON,
    OrderSummaryLayoutEnumFromJSONTyped,
    OrderSummaryLayoutEnumToJSON,
} from './OrderSummaryLayoutEnum';
import type { PaymentScreenEnum } from './PaymentScreenEnum';
import {
    PaymentScreenEnumFromJSON,
    PaymentScreenEnumFromJSONTyped,
    PaymentScreenEnumToJSON,
} from './PaymentScreenEnum';
import type { ReportTypeEnum } from './ReportTypeEnum';
import {
    ReportTypeEnumFromJSON,
    ReportTypeEnumFromJSONTyped,
    ReportTypeEnumToJSON,
} from './ReportTypeEnum';
import type { OrderSummaryEnum } from './OrderSummaryEnum';
import {
    OrderSummaryEnumFromJSON,
    OrderSummaryEnumFromJSONTyped,
    OrderSummaryEnumToJSON,
} from './OrderSummaryEnum';
import type { ProductLookupKeyEnum } from './ProductLookupKeyEnum';
import {
    ProductLookupKeyEnumFromJSON,
    ProductLookupKeyEnumFromJSONTyped,
    ProductLookupKeyEnumToJSON,
} from './ProductLookupKeyEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';
import type { LandingPageVariantEnum } from './LandingPageVariantEnum';
import {
    LandingPageVariantEnumFromJSON,
    LandingPageVariantEnumFromJSONTyped,
    LandingPageVariantEnumToJSON,
} from './LandingPageVariantEnum';
import type { SignaturePageEnum } from './SignaturePageEnum';
import {
    SignaturePageEnumFromJSON,
    SignaturePageEnumFromJSONTyped,
    SignaturePageEnumToJSON,
} from './SignaturePageEnum';

/**
 * 
 * @export
 * @interface CartConfig
 */
export interface CartConfig {
    /**
     * A slug to identify carts.
     * @type {string}
     * @memberof CartConfig
     */
    slug: string;
    /**
     * The payment page to use for this cart.
     * 
     * * `checkout` - Stripe Checkout
     * * `elements` - Payment Elements
     * @type {PaymentScreenEnum}
     * @memberof CartConfig
     */
    paymentScreen?: PaymentScreenEnum;
    /**
     * Show the side navigation in the cart?
     * @type {boolean}
     * @memberof CartConfig
     */
    includeNav?: boolean;
    /**
     * Is this the default CartConfig?
     * @type {boolean}
     * @memberof CartConfig
     */
    isDefault?: boolean;
    /**
     * 
     * @type {OrderSummaryEnum}
     * @memberof CartConfig
     */
    orderSummary?: OrderSummaryEnum;
    /**
     * 
     * @type {OrderSummaryLayoutEnum}
     * @memberof CartConfig
     */
    orderSummaryLayout?: OrderSummaryLayoutEnum;
    /**
     * The signature page to use for this cart.
     * 
     * * `V1` - Version 1: Select w/Signature
     * * `V2` - Version 2: Radio Buttons w/o Signature
     * @type {SignaturePageEnum}
     * @memberof CartConfig
     */
    signaturePage?: SignaturePageEnum;
    /**
     * Include a disclaimer on the payment page?
     * @type {boolean}
     * @memberof CartConfig
     */
    includeDisclaimer?: boolean;
    /**
     * Include the FL Late Fee?
     * @type {boolean}
     * @memberof CartConfig
     */
    includeLateFee?: boolean;
    /**
     * 
     * @type {ProductLookupKeyEnum}
     * @memberof CartConfig
     */
    productLookupKey?: ProductLookupKeyEnum;
    /**
     * 
     * @type {number}
     * @memberof CartConfig
     */
    featureFlag?: number | null;
    /**
     * 
     * @type {ReportTypeEnum}
     * @memberof CartConfig
     */
    reportType?: ReportTypeEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof CartConfig
     */
    jurisdiction?: JurisdictionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CartConfig
     */
    firstSlideAsLandingPage?: boolean;
    /**
     * An urgency banner that if populated will be displayed on the cart landing page.
     * @type {string}
     * @memberof CartConfig
     */
    urgencyBanner?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CartConfig
     */
    includeFilingDetailsOnLander?: boolean;
    /**
     * 
     * @type {LandingPageVariantEnum}
     * @memberof CartConfig
     */
    landingPageVariant?: LandingPageVariantEnum;
    /**
     * Override the cart title.
     * @type {string}
     * @memberof CartConfig
     */
    cartTitleOverride?: string;
    /**
     * Override the cart subtitle.
     * @type {string}
     * @memberof CartConfig
     */
    cartSubtitleOverride?: string;
}



/**
 * Check if a given object implements the CartConfig interface.
 */
export function instanceOfCartConfig(value: object): value is CartConfig {
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function CartConfigFromJSON(json: any): CartConfig {
    return CartConfigFromJSONTyped(json, false);
}

export function CartConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'slug': json['slug'],
        'paymentScreen': json['payment_screen'] == null ? undefined : PaymentScreenEnumFromJSON(json['payment_screen']),
        'includeNav': json['include_nav'] == null ? undefined : json['include_nav'],
        'isDefault': json['is_default'] == null ? undefined : json['is_default'],
        'orderSummary': json['order_summary'] == null ? undefined : OrderSummaryEnumFromJSON(json['order_summary']),
        'orderSummaryLayout': json['order_summary_layout'] == null ? undefined : OrderSummaryLayoutEnumFromJSON(json['order_summary_layout']),
        'signaturePage': json['signature_page'] == null ? undefined : SignaturePageEnumFromJSON(json['signature_page']),
        'includeDisclaimer': json['include_disclaimer'] == null ? undefined : json['include_disclaimer'],
        'includeLateFee': json['include_late_fee'] == null ? undefined : json['include_late_fee'],
        'productLookupKey': json['product_lookup_key'] == null ? undefined : ProductLookupKeyEnumFromJSON(json['product_lookup_key']),
        'featureFlag': json['feature_flag'] == null ? undefined : json['feature_flag'],
        'reportType': json['report_type'] == null ? undefined : ReportTypeEnumFromJSON(json['report_type']),
        'jurisdiction': json['jurisdiction'] == null ? undefined : JurisdictionEnumFromJSON(json['jurisdiction']),
        'firstSlideAsLandingPage': json['first_slide_as_landing_page'] == null ? undefined : json['first_slide_as_landing_page'],
        'urgencyBanner': json['urgency_banner'] == null ? undefined : json['urgency_banner'],
        'includeFilingDetailsOnLander': json['include_filing_details_on_lander'] == null ? undefined : json['include_filing_details_on_lander'],
        'landingPageVariant': json['landing_page_variant'] == null ? undefined : LandingPageVariantEnumFromJSON(json['landing_page_variant']),
        'cartTitleOverride': json['cart_title_override'] == null ? undefined : json['cart_title_override'],
        'cartSubtitleOverride': json['cart_subtitle_override'] == null ? undefined : json['cart_subtitle_override'],
    };
}

export function CartConfigToJSON(value?: CartConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'slug': value['slug'],
        'payment_screen': PaymentScreenEnumToJSON(value['paymentScreen']),
        'include_nav': value['includeNav'],
        'is_default': value['isDefault'],
        'order_summary': OrderSummaryEnumToJSON(value['orderSummary']),
        'order_summary_layout': OrderSummaryLayoutEnumToJSON(value['orderSummaryLayout']),
        'signature_page': SignaturePageEnumToJSON(value['signaturePage']),
        'include_disclaimer': value['includeDisclaimer'],
        'include_late_fee': value['includeLateFee'],
        'product_lookup_key': ProductLookupKeyEnumToJSON(value['productLookupKey']),
        'feature_flag': value['featureFlag'],
        'report_type': ReportTypeEnumToJSON(value['reportType']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'first_slide_as_landing_page': value['firstSlideAsLandingPage'],
        'urgency_banner': value['urgencyBanner'],
        'include_filing_details_on_lander': value['includeFilingDetailsOnLander'],
        'landing_page_variant': LandingPageVariantEnumToJSON(value['landingPageVariant']),
        'cart_title_override': value['cartTitleOverride'],
        'cart_subtitle_override': value['cartSubtitleOverride'],
    };
}

