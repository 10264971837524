/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `admin` - Administrator
 * * `member` - Member
 * @export
 * @enum {string}
 */
export enum RoleEnum {
    Admin = 'admin',
    Member = 'member'
}


export function instanceOfRoleEnum(value: any): boolean {
    for (const key in RoleEnum) {
        if (Object.prototype.hasOwnProperty.call(RoleEnum, key)) {
            if (RoleEnum[key as keyof typeof RoleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoleEnumFromJSON(json: any): RoleEnum {
    return RoleEnumFromJSONTyped(json, false);
}

export function RoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleEnum {
    return json as RoleEnum;
}

export function RoleEnumToJSON(value?: RoleEnum | null): any {
    return value as any;
}

