/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for requesting a password reset e-mail.
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    email: string;
}

/**
 * Check if a given object implements the PasswordReset interface.
 */
export function instanceOfPasswordReset(value: object): value is PasswordReset {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function PasswordResetFromJSON(json: any): PasswordReset {
    return PasswordResetFromJSONTyped(json, false);
}

export function PasswordResetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordReset {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function PasswordResetToJSON(value?: PasswordReset | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

