/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `fl` - Florida
 * * `fed` - Federal
 * * `wa` - Washington
 * @export
 * @enum {string}
 */
export enum JurisdictionEnum {
    Fl = 'fl',
    Fed = 'fed',
    Wa = 'wa'
}


export function instanceOfJurisdictionEnum(value: any): boolean {
    for (const key in JurisdictionEnum) {
        if (Object.prototype.hasOwnProperty.call(JurisdictionEnum, key)) {
            if (JurisdictionEnum[key as keyof typeof JurisdictionEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function JurisdictionEnumFromJSON(json: any): JurisdictionEnum {
    return JurisdictionEnumFromJSONTyped(json, false);
}

export function JurisdictionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): JurisdictionEnum {
    return json as JurisdictionEnum;
}

export function JurisdictionEnumToJSON(value?: JurisdictionEnum | null): any {
    return value as any;
}

