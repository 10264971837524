/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `annual_report` - Annual Report
 * * `boi_report` - Beneficial Ownership Information Report
 * @export
 * @enum {string}
 */
export enum ReportTypeEnum {
    AnnualReport = 'annual_report',
    BoiReport = 'boi_report'
}


export function instanceOfReportTypeEnum(value: any): boolean {
    for (const key in ReportTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ReportTypeEnum, key)) {
            if (ReportTypeEnum[key as keyof typeof ReportTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReportTypeEnumFromJSON(json: any): ReportTypeEnum {
    return ReportTypeEnumFromJSONTyped(json, false);
}

export function ReportTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTypeEnum {
    return json as ReportTypeEnum;
}

export function ReportTypeEnumToJSON(value?: ReportTypeEnum | null): any {
    return value as any;
}

