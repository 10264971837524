/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `checkout` - Stripe Checkout
 * * `elements` - Payment Elements
 * @export
 * @enum {string}
 */
export enum PaymentScreenEnum {
    Checkout = 'checkout',
    Elements = 'elements'
}


export function instanceOfPaymentScreenEnum(value: any): boolean {
    for (const key in PaymentScreenEnum) {
        if (Object.prototype.hasOwnProperty.call(PaymentScreenEnum, key)) {
            if (PaymentScreenEnum[key as keyof typeof PaymentScreenEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentScreenEnumFromJSON(json: any): PaymentScreenEnum {
    return PaymentScreenEnumFromJSONTyped(json, false);
}

export function PaymentScreenEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentScreenEnum {
    return json as PaymentScreenEnum;
}

export function PaymentScreenEnumToJSON(value?: PaymentScreenEnum | null): any {
    return value as any;
}

