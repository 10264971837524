/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `created` - Created
 * * `progress` - In Progress
 * * `submitted_for_processing` - Submitted for Processing
 * * `reviewed` - Reviewed
 * * `revision` - Revision Requested
 * * `filed` - Filed
 * * `approved` - Approved
 * * `canceled` - Canceled
 * * `refunded` - Refunded
 * * `alreadyfiled` - Already Filed
 * * `test` - Test Order
 * * `processing` - Processing
 * @export
 * @enum {string}
 */
export enum FilingStatusEnum {
    Created = 'created',
    Progress = 'progress',
    SubmittedForProcessing = 'submitted_for_processing',
    Reviewed = 'reviewed',
    Revision = 'revision',
    Filed = 'filed',
    Approved = 'approved',
    Canceled = 'canceled',
    Refunded = 'refunded',
    Alreadyfiled = 'alreadyfiled',
    Test = 'test',
    Processing = 'processing'
}


export function instanceOfFilingStatusEnum(value: any): boolean {
    for (const key in FilingStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(FilingStatusEnum, key)) {
            if (FilingStatusEnum[key as keyof typeof FilingStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FilingStatusEnumFromJSON(json: any): FilingStatusEnum {
    return FilingStatusEnumFromJSONTyped(json, false);
}

export function FilingStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilingStatusEnum {
    return json as FilingStatusEnum;
}

export function FilingStatusEnumToJSON(value?: FilingStatusEnum | null): any {
    return value as any;
}

