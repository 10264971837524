/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySearchResponse } from './CompanySearchResponse';
import {
    CompanySearchResponseFromJSON,
    CompanySearchResponseFromJSONTyped,
    CompanySearchResponseToJSON,
} from './CompanySearchResponse';

/**
 * 
 * @export
 * @interface CompanySearchResponseList
 */
export interface CompanySearchResponseList {
    /**
     * 
     * @type {Array<CompanySearchResponse>}
     * @memberof CompanySearchResponseList
     */
    companies: Array<CompanySearchResponse>;
}

/**
 * Check if a given object implements the CompanySearchResponseList interface.
 */
export function instanceOfCompanySearchResponseList(value: object): value is CompanySearchResponseList {
    if (!('companies' in value) || value['companies'] === undefined) return false;
    return true;
}

export function CompanySearchResponseListFromJSON(json: any): CompanySearchResponseList {
    return CompanySearchResponseListFromJSONTyped(json, false);
}

export function CompanySearchResponseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySearchResponseList {
    if (json == null) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(CompanySearchResponseFromJSON)),
    };
}

export function CompanySearchResponseListToJSON(value?: CompanySearchResponseList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companies': ((value['companies'] as Array<any>).map(CompanySearchResponseToJSON)),
    };
}

