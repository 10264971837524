/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `active` - Active
 * * `canceled` - Canceled
 * * `incomplete` - Incomplete
 * * `incomplete_expired` - Incomplete Expired
 * * `past_due` - Past due
 * * `trialing` - Trialing
 * * `unpaid` - Unpaid
 * @export
 * @enum {string}
 */
export enum SubscriptionStatusEnum {
    Active = 'active',
    Canceled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Trialing = 'trialing',
    Unpaid = 'unpaid'
}


export function instanceOfSubscriptionStatusEnum(value: any): boolean {
    for (const key in SubscriptionStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(SubscriptionStatusEnum, key)) {
            if (SubscriptionStatusEnum[key as keyof typeof SubscriptionStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SubscriptionStatusEnumFromJSON(json: any): SubscriptionStatusEnum {
    return SubscriptionStatusEnumFromJSONTyped(json, false);
}

export function SubscriptionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatusEnum {
    return json as SubscriptionStatusEnum;
}

export function SubscriptionStatusEnumToJSON(value?: SubscriptionStatusEnum | null): any {
    return value as any;
}

