/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CartStep
 */
export interface CartStep {
    /**
     * 
     * @type {string}
     * @memberof CartStep
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CartStep
     */
    subtitle?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CartStep
     */
    readonly formMetadata: { [key: string]: any; };
    /**
     * A slug to identify cart steps in the url.
     * @type {string}
     * @memberof CartStep
     */
    slug?: string;
}

/**
 * Check if a given object implements the CartStep interface.
 */
export function instanceOfCartStep(value: object): value is CartStep {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('formMetadata' in value) || value['formMetadata'] === undefined) return false;
    return true;
}

export function CartStepFromJSON(json: any): CartStep {
    return CartStepFromJSONTyped(json, false);
}

export function CartStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartStep {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'subtitle': json['subtitle'] == null ? undefined : json['subtitle'],
        'formMetadata': json['form_metadata'],
        'slug': json['slug'] == null ? undefined : json['slug'],
    };
}

export function CartStepToJSON(value?: Omit<CartStep, 'form_metadata'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'subtitle': value['subtitle'],
        'slug': value['slug'],
    };
}

