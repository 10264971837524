/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Officer } from './Officer';
import {
    OfficerFromJSON,
    OfficerFromJSONTyped,
    OfficerToJSON,
} from './Officer';

/**
 * 
 * @export
 * @interface PaginatedOfficerList
 */
export interface PaginatedOfficerList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedOfficerList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOfficerList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedOfficerList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Officer>}
     * @memberof PaginatedOfficerList
     */
    results: Array<Officer>;
}

/**
 * Check if a given object implements the PaginatedOfficerList interface.
 */
export function instanceOfPaginatedOfficerList(value: object): value is PaginatedOfficerList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedOfficerListFromJSON(json: any): PaginatedOfficerList {
    return PaginatedOfficerListFromJSONTyped(json, false);
}

export function PaginatedOfficerListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedOfficerList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(OfficerFromJSON)),
    };
}

export function PaginatedOfficerListToJSON(value?: PaginatedOfficerList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(OfficerToJSON)),
    };
}

