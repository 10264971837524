/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `heroV1` - Hero V1
 * * `first_slide` - First Slide as Lander
 * * `filing_details` - Original Filing Details
 * * `heroV2` - Hero V2
 * @export
 * @enum {string}
 */
export enum LandingPageVariantEnum {
    HeroV1 = 'heroV1',
    FirstSlide = 'first_slide',
    FilingDetails = 'filing_details',
    HeroV2 = 'heroV2'
}


export function instanceOfLandingPageVariantEnum(value: any): boolean {
    for (const key in LandingPageVariantEnum) {
        if (Object.prototype.hasOwnProperty.call(LandingPageVariantEnum, key)) {
            if (LandingPageVariantEnum[key as keyof typeof LandingPageVariantEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LandingPageVariantEnumFromJSON(json: any): LandingPageVariantEnum {
    return LandingPageVariantEnumFromJSONTyped(json, false);
}

export function LandingPageVariantEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageVariantEnum {
    return json as LandingPageVariantEnum;
}

export function LandingPageVariantEnumToJSON(value?: LandingPageVariantEnum | null): any {
    return value as any;
}

