/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `I` - Included
 * * `A` - Applied For
 * * `NA` - Not Applicable
 * @export
 * @enum {string}
 */
export enum FeinStatusEnum {
    I = 'I',
    A = 'A',
    Na = 'NA'
}


export function instanceOfFeinStatusEnum(value: any): boolean {
    for (const key in FeinStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(FeinStatusEnum, key)) {
            if (FeinStatusEnum[key as keyof typeof FeinStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FeinStatusEnumFromJSON(json: any): FeinStatusEnum {
    return FeinStatusEnumFromJSONTyped(json, false);
}

export function FeinStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeinStatusEnum {
    return json as FeinStatusEnum;
}

export function FeinStatusEnumToJSON(value?: FeinStatusEnum | null): any {
    return value as any;
}

