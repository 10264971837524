/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly humanReadablePrice: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly paymentAmount: string;
    /**
     * A brief description of the plan, hidden from customers.
     * @type {string}
     * @memberof Price
     */
    nickname?: string;
    /**
     * The unit amount in cents to be charged, represented as a whole integer if possible. Null if a sub-cent precision is required.
     * @type {number}
     * @memberof Price
     */
    unitAmount?: number | null;
}

/**
 * Check if a given object implements the Price interface.
 */
export function instanceOfPrice(value: object): value is Price {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('productName' in value) || value['productName'] === undefined) return false;
    if (!('humanReadablePrice' in value) || value['humanReadablePrice'] === undefined) return false;
    if (!('paymentAmount' in value) || value['paymentAmount'] === undefined) return false;
    return true;
}

export function PriceFromJSON(json: any): Price {
    return PriceFromJSONTyped(json, false);
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productName': json['product_name'],
        'humanReadablePrice': json['human_readable_price'],
        'paymentAmount': json['payment_amount'],
        'nickname': json['nickname'] == null ? undefined : json['nickname'],
        'unitAmount': json['unit_amount'] == null ? undefined : json['unit_amount'],
    };
}

export function PriceToJSON(value?: Omit<Price, 'human_readable_price'|'payment_amount'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'product_name': value['productName'],
        'nickname': value['nickname'],
        'unit_amount': value['unitAmount'],
    };
}

