/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateFilingFilingTypeEnum } from './CreateFilingFilingTypeEnum';
import {
    CreateFilingFilingTypeEnumFromJSON,
    CreateFilingFilingTypeEnumFromJSONTyped,
    CreateFilingFilingTypeEnumToJSON,
} from './CreateFilingFilingTypeEnum';
import type { BoiFilingTypeEnum } from './BoiFilingTypeEnum';
import {
    BoiFilingTypeEnumFromJSON,
    BoiFilingTypeEnumFromJSONTyped,
    BoiFilingTypeEnumToJSON,
} from './BoiFilingTypeEnum';

/**
 * 
 * @export
 * @interface CreateFiling
 */
export interface CreateFiling {
    /**
     * Type of filing to create.
     * 
     * * `annual_report` - Annual Report
     * * `boi_report` - Beneficial Ownership Report
     * @type {CreateFilingFilingTypeEnum}
     * @memberof CreateFiling
     */
    filingType: CreateFilingFilingTypeEnum;
    /**
     * The ID of the filing to copy data from.
     * @type {number}
     * @memberof CreateFiling
     */
    sourceFiling: number;
    /**
     * Type of Beneficial Ownership Report to create.
     * 
     * * `correction` - Correct Prior Report
     * * `update` - Update Prior Report
     * * `exemption` - Newly Exempt Entity
     * @type {BoiFilingTypeEnum}
     * @memberof CreateFiling
     */
    boiFilingType?: BoiFilingTypeEnum;
}



/**
 * Check if a given object implements the CreateFiling interface.
 */
export function instanceOfCreateFiling(value: object): value is CreateFiling {
    if (!('filingType' in value) || value['filingType'] === undefined) return false;
    if (!('sourceFiling' in value) || value['sourceFiling'] === undefined) return false;
    return true;
}

export function CreateFilingFromJSON(json: any): CreateFiling {
    return CreateFilingFromJSONTyped(json, false);
}

export function CreateFilingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFiling {
    if (json == null) {
        return json;
    }
    return {
        
        'filingType': CreateFilingFilingTypeEnumFromJSON(json['filing_type']),
        'sourceFiling': json['source_filing'],
        'boiFilingType': json['boi_filing_type'] == null ? undefined : BoiFilingTypeEnumFromJSON(json['boi_filing_type']),
    };
}

export function CreateFilingToJSON(value?: CreateFiling | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filing_type': CreateFilingFilingTypeEnumToJSON(value['filingType']),
        'source_filing': value['sourceFiling'],
        'boi_filing_type': BoiFilingTypeEnumToJSON(value['boiFilingType']),
    };
}

