/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartConfig } from './CartConfig';
import {
    CartConfigFromJSON,
    CartConfigFromJSONTyped,
    CartConfigToJSON,
} from './CartConfig';
import type { CartStep } from './CartStep';
import {
    CartStepFromJSON,
    CartStepFromJSONTyped,
    CartStepToJSON,
} from './CartStep';
import type { PaymentScreenEnum } from './PaymentScreenEnum';
import {
    PaymentScreenEnumFromJSON,
    PaymentScreenEnumFromJSONTyped,
    PaymentScreenEnumToJSON,
} from './PaymentScreenEnum';

/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    subtitle: string;
    /**
     * 
     * @type {Array<CartStep>}
     * @memberof Cart
     */
    readonly cartSteps: Array<CartStep>;
    /**
     * Method to get initial form data for a cart.
     * :return: serialized representation of the queryset returnedby the get_prepopulated_data method.
     * @type {{ [key: string]: any; }}
     * @memberof Cart
     */
    readonly initialFormData: { [key: string]: any; };
    /**
     * A slug to identify carts.
     * @type {string}
     * @memberof Cart
     */
    slug: string;
    /**
     * Show the side navigation in the cart?
     * @type {boolean}
     * @memberof Cart
     */
    includeNav?: boolean;
    /**
     * The payment page to use for this cart.
     * 
     * * `checkout` - Stripe Checkout
     * * `elements` - Payment Elements
     * @type {PaymentScreenEnum}
     * @memberof Cart
     */
    paymentScreen?: PaymentScreenEnum;
    /**
     * 
     * @type {CartConfig}
     * @memberof Cart
     */
    readonly cartConfig: CartConfig;
}



/**
 * Check if a given object implements the Cart interface.
 */
export function instanceOfCart(value: object): value is Cart {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('subtitle' in value) || value['subtitle'] === undefined) return false;
    if (!('cartSteps' in value) || value['cartSteps'] === undefined) return false;
    if (!('initialFormData' in value) || value['initialFormData'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    if (!('cartConfig' in value) || value['cartConfig'] === undefined) return false;
    return true;
}

export function CartFromJSON(json: any): Cart {
    return CartFromJSONTyped(json, false);
}

export function CartFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cart {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'subtitle': json['subtitle'],
        'cartSteps': ((json['cart_steps'] as Array<any>).map(CartStepFromJSON)),
        'initialFormData': json['initial_form_data'],
        'slug': json['slug'],
        'includeNav': json['include_nav'] == null ? undefined : json['include_nav'],
        'paymentScreen': json['payment_screen'] == null ? undefined : PaymentScreenEnumFromJSON(json['payment_screen']),
        'cartConfig': CartConfigFromJSON(json['cart_config']),
    };
}

export function CartToJSON(value?: Omit<Cart, 'id'|'cart_steps'|'initial_form_data'|'cart_config'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'subtitle': value['subtitle'],
        'slug': value['slug'],
        'include_nav': value['includeNav'],
        'payment_screen': PaymentScreenEnumToJSON(value['paymentScreen']),
    };
}

