/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `individual` - Individual
 * * `entity` - Business Entity
 * @export
 * @enum {string}
 */
export enum OwnerTypeEnum {
    Individual = 'individual',
    Entity = 'entity'
}


export function instanceOfOwnerTypeEnum(value: any): boolean {
    for (const key in OwnerTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(OwnerTypeEnum, key)) {
            if (OwnerTypeEnum[key as keyof typeof OwnerTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OwnerTypeEnumFromJSON(json: any): OwnerTypeEnum {
    return OwnerTypeEnumFromJSONTyped(json, false);
}

export function OwnerTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerTypeEnum {
    return json as OwnerTypeEnum;
}

export function OwnerTypeEnumToJSON(value?: OwnerTypeEnum | null): any {
    return value as any;
}

