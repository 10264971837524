/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AnnualReport } from './AnnualReport';
import {
    AnnualReportFromJSON,
    AnnualReportFromJSONTyped,
    AnnualReportToJSON,
} from './AnnualReport';

/**
 * 
 * @export
 * @interface PaginatedAnnualReportList
 */
export interface PaginatedAnnualReportList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAnnualReportList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnnualReportList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnnualReportList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AnnualReport>}
     * @memberof PaginatedAnnualReportList
     */
    results: Array<AnnualReport>;
}

/**
 * Check if a given object implements the PaginatedAnnualReportList interface.
 */
export function instanceOfPaginatedAnnualReportList(value: object): value is PaginatedAnnualReportList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedAnnualReportListFromJSON(json: any): PaginatedAnnualReportList {
    return PaginatedAnnualReportListFromJSONTyped(json, false);
}

export function PaginatedAnnualReportListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAnnualReportList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(AnnualReportFromJSON)),
    };
}

export function PaginatedAnnualReportListToJSON(value?: PaginatedAnnualReportList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(AnnualReportToJSON)),
    };
}

