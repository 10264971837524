/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `unpaid` - Unpaid
 * * `pending` - Pending
 * * `paid` - Paid
 * @export
 * @enum {string}
 */
export enum PaymentStatusEnum {
    Unpaid = 'unpaid',
    Pending = 'pending',
    Paid = 'paid'
}


export function instanceOfPaymentStatusEnum(value: any): boolean {
    for (const key in PaymentStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(PaymentStatusEnum, key)) {
            if (PaymentStatusEnum[key as keyof typeof PaymentStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentStatusEnumFromJSON(json: any): PaymentStatusEnum {
    return PaymentStatusEnumFromJSONTyped(json, false);
}

export function PaymentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatusEnum {
    return json as PaymentStatusEnum;
}

export function PaymentStatusEnumToJSON(value?: PaymentStatusEnum | null): any {
    return value as any;
}

