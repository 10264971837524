/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `state_drivers_license` - State Driver's License
 * * `state_id_card` - State Issued ID Card
 * * `us_passport` - US Passport
 * * `foreign_passport` - Foreign Passport
 * @export
 * @enum {string}
 */
export enum IdTypeEnum {
    StateDriversLicense = 'state_drivers_license',
    StateIdCard = 'state_id_card',
    UsPassport = 'us_passport',
    ForeignPassport = 'foreign_passport'
}


export function instanceOfIdTypeEnum(value: any): boolean {
    for (const key in IdTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(IdTypeEnum, key)) {
            if (IdTypeEnum[key as keyof typeof IdTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IdTypeEnumFromJSON(json: any): IdTypeEnum {
    return IdTypeEnumFromJSONTyped(json, false);
}

export function IdTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdTypeEnum {
    return json as IdTypeEnum;
}

export function IdTypeEnumToJSON(value?: IdTypeEnum | null): any {
    return value as any;
}

