/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Team } from './Team';
import {
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';

/**
 * Basic serializer to pass CustomUser details to the front end.
 * Extend with any fields your app needs.
 * @export
 * @interface CustomUser
 */
export interface CustomUser {
    /**
     * 
     * @type {number}
     * @memberof CustomUser
     */
    readonly pk: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof CustomUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    readonly email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomUser
     */
    readonly uuidFromEmail: string;
    /**
     * 
     * @type {Array<Team>}
     * @memberof CustomUser
     */
    readonly teams: Array<Team>;
}

/**
 * Check if a given object implements the CustomUser interface.
 */
export function instanceOfCustomUser(value: object): value is CustomUser {
    if (!('pk' in value) || value['pk'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('uuidFromEmail' in value) || value['uuidFromEmail'] === undefined) return false;
    if (!('teams' in value) || value['teams'] === undefined) return false;
    return true;
}

export function CustomUserFromJSON(json: any): CustomUser {
    return CustomUserFromJSONTyped(json, false);
}

export function CustomUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomUser {
    if (json == null) {
        return json;
    }
    return {
        
        'pk': json['pk'],
        'username': json['username'],
        'email': json['email'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'language': json['language'] == null ? undefined : json['language'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'uuidFromEmail': json['uuid_from_email'],
        'teams': ((json['teams'] as Array<any>).map(TeamFromJSON)),
    };
}

export function CustomUserToJSON(value?: Omit<CustomUser, 'pk'|'email'|'uuid_from_email'|'teams'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'middle_name': value['middleName'],
        'avatar': value['avatar'],
        'language': value['language'],
        'phone_number': value['phoneNumber'],
    };
}

