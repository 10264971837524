/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `business` - Business Address
 * * `residential` - Residential Address
 * @export
 * @enum {string}
 */
export enum AddressTypeEnum {
    Business = 'business',
    Residential = 'residential'
}


export function instanceOfAddressTypeEnum(value: any): boolean {
    for (const key in AddressTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(AddressTypeEnum, key)) {
            if (AddressTypeEnum[key as keyof typeof AddressTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AddressTypeEnumFromJSON(json: any): AddressTypeEnum {
    return AddressTypeEnumFromJSONTyped(json, false);
}

export function AddressTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressTypeEnum {
    return json as AddressTypeEnum;
}

export function AddressTypeEnumToJSON(value?: AddressTypeEnum | null): any {
    return value as any;
}

