/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `V1` - Version 1: Original Order Summary
 * * `V2` - Version 2: Condensed with Timer
 * * `V3` - Version 3: Remove Fake Discount
 * * `V4` - Version 4: BOI Cart Test
 * @export
 * @enum {string}
 */
export enum OrderSummaryEnum {
    V1 = 'V1',
    V2 = 'V2',
    V3 = 'V3',
    V4 = 'V4'
}


export function instanceOfOrderSummaryEnum(value: any): boolean {
    for (const key in OrderSummaryEnum) {
        if (Object.prototype.hasOwnProperty.call(OrderSummaryEnum, key)) {
            if (OrderSummaryEnum[key as keyof typeof OrderSummaryEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrderSummaryEnumFromJSON(json: any): OrderSummaryEnum {
    return OrderSummaryEnumFromJSONTyped(json, false);
}

export function OrderSummaryEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSummaryEnum {
    return json as OrderSummaryEnum;
}

export function OrderSummaryEnumToJSON(value?: OrderSummaryEnum | null): any {
    return value as any;
}

