/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressTypeEnum } from './AddressTypeEnum';
import {
    AddressTypeEnumFromJSON,
    AddressTypeEnumFromJSONTyped,
    AddressTypeEnumToJSON,
} from './AddressTypeEnum';
import type { FincenIdentifierTypeEnum } from './FincenIdentifierTypeEnum';
import {
    FincenIdentifierTypeEnumFromJSON,
    FincenIdentifierTypeEnumFromJSONTyped,
    FincenIdentifierTypeEnumToJSON,
} from './FincenIdentifierTypeEnum';
import type { IdTypeEnum } from './IdTypeEnum';
import {
    IdTypeEnumFromJSON,
    IdTypeEnumFromJSONTyped,
    IdTypeEnumToJSON,
} from './IdTypeEnum';

/**
 * 
 * @export
 * @interface PatchedCompanyApplicant
 */
export interface PatchedCompanyApplicant {
    /**
     * 
     * @type {number}
     * @memberof PatchedCompanyApplicant
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    idImage?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedCompanyApplicant
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedCompanyApplicant
     */
    readonly updatedAt?: Date;
    /**
     * FinCEN Identifier (if applicable)
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    fincenIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    middleName?: string;
    /**
     * e.g. Jr., Sr., III
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    suffix?: string;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedCompanyApplicant
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    addressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    addressState?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    addressZip?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    addressCountry?: string;
    /**
     * 
     * @type {IdTypeEnum}
     * @memberof PatchedCompanyApplicant
     */
    idType?: IdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    idNumber?: string | null;
    /**
     * Country of issuance (if Foreign Passport Only
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    idCountry?: string | null;
    /**
     * State of issuance (if DL or State ID
     * @type {string}
     * @memberof PatchedCompanyApplicant
     */
    idState?: string | null;
    /**
     * 
     * @type {AddressTypeEnum}
     * @memberof PatchedCompanyApplicant
     */
    addressType?: AddressTypeEnum;
    /**
     * 
     * @type {FincenIdentifierTypeEnum}
     * @memberof PatchedCompanyApplicant
     */
    fincenIdentifierType?: FincenIdentifierTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedCompanyApplicant
     */
    beneficialOwnershipReport?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedCompanyApplicant
     */
    beneficialOwner?: number | null;
}



/**
 * Check if a given object implements the PatchedCompanyApplicant interface.
 */
export function instanceOfPatchedCompanyApplicant(value: object): value is PatchedCompanyApplicant {
    return true;
}

export function PatchedCompanyApplicantFromJSON(json: any): PatchedCompanyApplicant {
    return PatchedCompanyApplicantFromJSONTyped(json, false);
}

export function PatchedCompanyApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedCompanyApplicant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'idImage': json['id_image'] == null ? undefined : json['id_image'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'fincenIdentifier': json['fincen_identifier'] == null ? undefined : json['fincen_identifier'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'suffix': json['suffix'] == null ? undefined : json['suffix'],
        'dateOfBirth': json['date_of_birth'] == null ? undefined : (new Date(json['date_of_birth'])),
        'addressLine1': json['address_line_1'] == null ? undefined : json['address_line_1'],
        'addressCity': json['address_city'] == null ? undefined : json['address_city'],
        'addressState': json['address_state'] == null ? undefined : json['address_state'],
        'addressZip': json['address_zip'] == null ? undefined : json['address_zip'],
        'addressCountry': json['address_country'] == null ? undefined : json['address_country'],
        'idType': json['id_type'] == null ? undefined : IdTypeEnumFromJSON(json['id_type']),
        'idNumber': json['id_number'] == null ? undefined : json['id_number'],
        'idCountry': json['id_country'] == null ? undefined : json['id_country'],
        'idState': json['id_state'] == null ? undefined : json['id_state'],
        'addressType': json['address_type'] == null ? undefined : AddressTypeEnumFromJSON(json['address_type']),
        'fincenIdentifierType': json['fincen_identifier_type'] == null ? undefined : FincenIdentifierTypeEnumFromJSON(json['fincen_identifier_type']),
        'beneficialOwnershipReport': json['beneficial_ownership_report'] == null ? undefined : json['beneficial_ownership_report'],
        'beneficialOwner': json['beneficial_owner'] == null ? undefined : json['beneficial_owner'],
    };
}

export function PatchedCompanyApplicantToJSON(value?: Omit<PatchedCompanyApplicant, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id_image': value['idImage'],
        'fincen_identifier': value['fincenIdentifier'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'middle_name': value['middleName'],
        'suffix': value['suffix'],
        'date_of_birth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString().substring(0,10)),
        'address_line_1': value['addressLine1'],
        'address_city': value['addressCity'],
        'address_state': value['addressState'],
        'address_zip': value['addressZip'],
        'address_country': value['addressCountry'],
        'id_type': IdTypeEnumToJSON(value['idType']),
        'id_number': value['idNumber'],
        'id_country': value['idCountry'],
        'id_state': value['idState'],
        'address_type': AddressTypeEnumToJSON(value['addressType']),
        'fincen_identifier_type': FincenIdentifierTypeEnumToJSON(value['fincenIdentifierType']),
        'beneficial_ownership_report': value['beneficialOwnershipReport'],
        'beneficial_owner': value['beneficialOwner'],
    };
}

