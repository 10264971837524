/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingDetails } from './FilingDetails';
import {
    FilingDetailsFromJSON,
    FilingDetailsFromJSONTyped,
    FilingDetailsToJSON,
} from './FilingDetails';

/**
 * 
 * @export
 * @interface PaginatedFilingDetailsList
 */
export interface PaginatedFilingDetailsList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedFilingDetailsList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFilingDetailsList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFilingDetailsList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<FilingDetails>}
     * @memberof PaginatedFilingDetailsList
     */
    results: Array<FilingDetails>;
}

/**
 * Check if a given object implements the PaginatedFilingDetailsList interface.
 */
export function instanceOfPaginatedFilingDetailsList(value: object): value is PaginatedFilingDetailsList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedFilingDetailsListFromJSON(json: any): PaginatedFilingDetailsList {
    return PaginatedFilingDetailsListFromJSONTyped(json, false);
}

export function PaginatedFilingDetailsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedFilingDetailsList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(FilingDetailsFromJSON)),
    };
}

export function PaginatedFilingDetailsListToJSON(value?: PaginatedFilingDetailsList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(FilingDetailsToJSON)),
    };
}

