/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `fincen_id` - FinCEN ID
 * * `beneficial_owner` - Beneficial Owner
 * * `full_upload` - No FinCEN ID - Full Upload
 * @export
 * @enum {string}
 */
export enum FincenIdentifierTypeEnum {
    FincenId = 'fincen_id',
    BeneficialOwner = 'beneficial_owner',
    FullUpload = 'full_upload'
}


export function instanceOfFincenIdentifierTypeEnum(value: any): boolean {
    for (const key in FincenIdentifierTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(FincenIdentifierTypeEnum, key)) {
            if (FincenIdentifierTypeEnum[key as keyof typeof FincenIdentifierTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FincenIdentifierTypeEnumFromJSON(json: any): FincenIdentifierTypeEnum {
    return FincenIdentifierTypeEnumFromJSONTyped(json, false);
}

export function FincenIdentifierTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FincenIdentifierTypeEnum {
    return json as FincenIdentifierTypeEnum;
}

export function FincenIdentifierTypeEnumToJSON(value?: FincenIdentifierTypeEnum | null): any {
    return value as any;
}

