/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfficerTypeEnum } from './OfficerTypeEnum';
import {
    OfficerTypeEnumFromJSON,
    OfficerTypeEnumFromJSONTyped,
    OfficerTypeEnumToJSON,
} from './OfficerTypeEnum';

/**
 * 
 * @export
 * @interface Officer
 */
export interface Officer {
    /**
     * 
     * @type {number}
     * @memberof Officer
     */
    readonly id: number;
    /**
     * Officer Title.
     * @type {string}
     * @memberof Officer
     */
    title: string;
    /**
     * (P) - Person. (C) - Corporation.
     * 
     * * `P` - Person
     * * `C` - Corporation
     * @type {OfficerTypeEnum}
     * @memberof Officer
     */
    officerType: OfficerTypeEnum;
    /**
     * Officer Name
     * @type {string}
     * @memberof Officer
     */
    name: string;
    /**
     * Officer First Name
     * @type {string}
     * @memberof Officer
     */
    firstName?: string;
    /**
     * Officer Middle Name
     * @type {string}
     * @memberof Officer
     */
    middleName?: string;
    /**
     * Officer Last Name
     * @type {string}
     * @memberof Officer
     */
    lastName?: string;
    /**
     * Officer Address
     * @type {string}
     * @memberof Officer
     */
    address1: string;
    /**
     * Officer Address 2
     * @type {string}
     * @memberof Officer
     */
    address2?: string;
    /**
     * Officer City
     * @type {string}
     * @memberof Officer
     */
    city: string;
    /**
     * Officer State
     * @type {string}
     * @memberof Officer
     */
    state: string;
    /**
     * Officer Zip+4
     * @type {string}
     * @memberof Officer
     */
    zip: string;
    /**
     * 
     * @type {number}
     * @memberof Officer
     */
    corporation?: number | null;
}



/**
 * Check if a given object implements the Officer interface.
 */
export function instanceOfOfficer(value: object): value is Officer {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('officerType' in value) || value['officerType'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    return true;
}

export function OfficerFromJSON(json: any): Officer {
    return OfficerFromJSONTyped(json, false);
}

export function OfficerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Officer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'officerType': OfficerTypeEnumFromJSON(json['officer_type']),
        'name': json['name'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'corporation': json['corporation'] == null ? undefined : json['corporation'],
    };
}

export function OfficerToJSON(value?: Omit<Officer, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'officer_type': OfficerTypeEnumToJSON(value['officerType']),
        'name': value['name'],
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'corporation': value['corporation'],
    };
}

