/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { ReminderStatusEnum } from './ReminderStatusEnum';
import {
    ReminderStatusEnumFromJSON,
    ReminderStatusEnumFromJSONTyped,
    ReminderStatusEnumToJSON,
} from './ReminderStatusEnum';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';

/**
 * 
 * @export
 * @interface PatchedDynamicFiling
 */
export interface PatchedDynamicFiling {
    /**
     * 
     * @type {number}
     * @memberof PatchedDynamicFiling
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof PatchedDynamicFiling
     */
    reportName?: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof PatchedDynamicFiling
     */
    jurisdiction?: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof PatchedDynamicFiling
     */
    filingStatus?: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedDynamicFiling
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof PatchedDynamicFiling
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * Reminder status
     * 
     * * `do_not_remind` - Do Not Remind
     * * `queued_for_reminder` - Queued for Reminder
     * * `sent_1d_reminder` - Sent 1 Day Reminder
     * * `sent_7d_reminder` - Sent 7 Day Reminder
     * * `sent_14d_reminder` - Sent 14 Day Reminder
     * * `sent_30d_reminder` - Sent 30 Day Reminder
     * * `next_reminder_phase` - Next Reminder Phase
     * * `new_filing` - New Filing Alert
     * * `boi_needed` - BOI Data Needed
     * * `annual_to_boi_remind` - Annual to BOI Reminder
     * * `boi_data_series` - BOI Data Series
     * * `error` - Error
     * @type {ReminderStatusEnum}
     * @memberof PatchedDynamicFiling
     */
    reminderStatus?: ReminderStatusEnum;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedDynamicFiling
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedDynamicFiling
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedDynamicFiling
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedDynamicFiling
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDynamicFiling
     */
    emailReminderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedDynamicFiling
     */
    team?: number;
}



/**
 * Check if a given object implements the PatchedDynamicFiling interface.
 */
export function instanceOfPatchedDynamicFiling(value: object): value is PatchedDynamicFiling {
    return true;
}

export function PatchedDynamicFilingFromJSON(json: any): PatchedDynamicFiling {
    return PatchedDynamicFilingFromJSONTyped(json, false);
}

export function PatchedDynamicFilingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedDynamicFiling {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'reportName': json['report_name'] == null ? undefined : ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': json['jurisdiction'] == null ? undefined : JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': json['filing_status'] == null ? undefined : FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'reminderStatus': json['reminder_status'] == null ? undefined : ReminderStatusEnumFromJSON(json['reminder_status']),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'emailReminderStatus': json['email_reminder_status'] == null ? undefined : json['email_reminder_status'],
        'team': json['team'] == null ? undefined : json['team'],
    };
}

export function PatchedDynamicFilingToJSON(value?: Omit<PatchedDynamicFiling, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'reminder_status': ReminderStatusEnumToJSON(value['reminderStatus']),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'email_reminder_status': value['emailReminderStatus'],
        'team': value['team'],
    };
}

