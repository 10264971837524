/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionItem } from './SubscriptionItem';
import {
    SubscriptionItemFromJSON,
    SubscriptionItemFromJSONTyped,
    SubscriptionItemToJSON,
} from './SubscriptionItem';
import type { SubscriptionStatusEnum } from './SubscriptionStatusEnum';
import {
    SubscriptionStatusEnumFromJSON,
    SubscriptionStatusEnumFromJSONTyped,
    SubscriptionStatusEnumToJSON,
} from './SubscriptionStatusEnum';

/**
 * A serializer for Subscriptions which uses the SubscriptionWrapper object under the hood
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    displayName: string;
    /**
     * Date when the subscription was first created. The date might differ from the created date due to backdating.
     * @type {Date}
     * @memberof Subscription
     */
    startDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    billingInterval: string;
    /**
     * Start of the current period for which the subscription has been invoiced.
     * @type {Date}
     * @memberof Subscription
     */
    currentPeriodStart: Date;
    /**
     * End of the current period for which the subscription has been invoiced. At the end of this period, a new invoice will be created.
     * @type {Date}
     * @memberof Subscription
     */
    currentPeriodEnd: Date;
    /**
     * If the subscription has been canceled with the ``at_period_end`` flag set to true, ``cancel_at_period_end`` on the subscription will be true. You can use this attribute to determine whether a subscription that has a status of active is scheduled to be canceled at the end of the current period.
     * @type {boolean}
     * @memberof Subscription
     */
    cancelAtPeriodEnd?: boolean;
    /**
     * The status of this subscription.
     * 
     * * `active` - Active
     * * `canceled` - Canceled
     * * `incomplete` - Incomplete
     * * `incomplete_expired` - Incomplete Expired
     * * `past_due` - Past due
     * * `trialing` - Trialing
     * * `unpaid` - Unpaid
     * @type {SubscriptionStatusEnum}
     * @memberof Subscription
     */
    status: SubscriptionStatusEnum;
    /**
     * The quantity applied to this subscription. This value will be `null` for multi-plan subscriptions
     * @type {number}
     * @memberof Subscription
     */
    quantity?: number | null;
    /**
     * 
     * @type {Array<SubscriptionItem>}
     * @memberof Subscription
     */
    items: Array<SubscriptionItem>;
}



/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): value is Subscription {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('billingInterval' in value) || value['billingInterval'] === undefined) return false;
    if (!('currentPeriodStart' in value) || value['currentPeriodStart'] === undefined) return false;
    if (!('currentPeriodEnd' in value) || value['currentPeriodEnd'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['display_name'],
        'startDate': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'billingInterval': json['billing_interval'],
        'currentPeriodStart': (new Date(json['current_period_start'])),
        'currentPeriodEnd': (new Date(json['current_period_end'])),
        'cancelAtPeriodEnd': json['cancel_at_period_end'] == null ? undefined : json['cancel_at_period_end'],
        'status': SubscriptionStatusEnumFromJSON(json['status']),
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'items': ((json['items'] as Array<any>).map(SubscriptionItemFromJSON)),
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'display_name': value['displayName'],
        'start_date': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString()),
        'billing_interval': value['billingInterval'],
        'current_period_start': ((value['currentPeriodStart']).toISOString()),
        'current_period_end': ((value['currentPeriodEnd']).toISOString()),
        'cancel_at_period_end': value['cancelAtPeriodEnd'],
        'status': SubscriptionStatusEnumToJSON(value['status']),
        'quantity': value['quantity'],
        'items': ((value['items'] as Array<any>).map(SubscriptionItemToJSON)),
    };
}

