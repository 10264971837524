/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AnnualReport } from './AnnualReport';
import {
    instanceOfAnnualReport,
    AnnualReportFromJSON,
    AnnualReportFromJSONTyped,
    AnnualReportToJSON,
} from './AnnualReport';
import type { BeneficialOwnershipReport } from './BeneficialOwnershipReport';
import {
    instanceOfBeneficialOwnershipReport,
    BeneficialOwnershipReportFromJSON,
    BeneficialOwnershipReportFromJSONTyped,
    BeneficialOwnershipReportToJSON,
} from './BeneficialOwnershipReport';

/**
 * @type FilingDetails
 * 
 * @export
 */
export type FilingDetails = { reportName: 'annual_report' } & AnnualReport | { reportName: 'boi_report' } & BeneficialOwnershipReport;

export function FilingDetailsFromJSON(json: any): FilingDetails {
    return FilingDetailsFromJSONTyped(json, false);
}

export function FilingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilingDetails {
    if (json == null) {
        return json;
    }
    switch (json['report_name']) {
        case 'annual_report':
            return Object.assign({}, AnnualReportFromJSONTyped(json, true), { reportName: 'annual_report' } as const);
        case 'boi_report':
            return Object.assign({}, BeneficialOwnershipReportFromJSONTyped(json, true), { reportName: 'boi_report' } as const);
        default:
            throw new Error(`No variant of FilingDetails exists with 'reportName=${json['reportName']}'`);
    }
}

export function FilingDetailsToJSON(value?: FilingDetails | null): any {
    if (value == null) {
        return value;
    }
    switch (value['reportName']) {
        case 'annual_report':
            return AnnualReportToJSON(value);
        case 'boi_report':
            return BeneficialOwnershipReportToJSON(value);
        default:
            throw new Error(`No variant of FilingDetails exists with 'reportName=${value['reportName']}'`);
    }

}

