/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `P` - Person
 * * `C` - Corporation
 * @export
 * @enum {string}
 */
export enum OfficerTypeEnum {
    P = 'P',
    C = 'C'
}


export function instanceOfOfficerTypeEnum(value: any): boolean {
    for (const key in OfficerTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(OfficerTypeEnum, key)) {
            if (OfficerTypeEnum[key as keyof typeof OfficerTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OfficerTypeEnumFromJSON(json: any): OfficerTypeEnum {
    return OfficerTypeEnumFromJSONTyped(json, false);
}

export function OfficerTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficerTypeEnum {
    return json as OfficerTypeEnum;
}

export function OfficerTypeEnumToJSON(value?: OfficerTypeEnum | null): any {
    return value as any;
}

