/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BeneficialOwnershipReport } from './BeneficialOwnershipReport';
import {
    BeneficialOwnershipReportFromJSON,
    BeneficialOwnershipReportFromJSONTyped,
    BeneficialOwnershipReportToJSON,
} from './BeneficialOwnershipReport';

/**
 * 
 * @export
 * @interface PaginatedBeneficialOwnershipReportList
 */
export interface PaginatedBeneficialOwnershipReportList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedBeneficialOwnershipReportList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBeneficialOwnershipReportList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBeneficialOwnershipReportList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BeneficialOwnershipReport>}
     * @memberof PaginatedBeneficialOwnershipReportList
     */
    results: Array<BeneficialOwnershipReport>;
}

/**
 * Check if a given object implements the PaginatedBeneficialOwnershipReportList interface.
 */
export function instanceOfPaginatedBeneficialOwnershipReportList(value: object): value is PaginatedBeneficialOwnershipReportList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedBeneficialOwnershipReportListFromJSON(json: any): PaginatedBeneficialOwnershipReportList {
    return PaginatedBeneficialOwnershipReportListFromJSONTyped(json, false);
}

export function PaginatedBeneficialOwnershipReportListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedBeneficialOwnershipReportList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(BeneficialOwnershipReportFromJSON)),
    };
}

export function PaginatedBeneficialOwnershipReportListToJSON(value?: PaginatedBeneficialOwnershipReportList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(BeneficialOwnershipReportToJSON)),
    };
}

