/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfficerTypeEnum } from './OfficerTypeEnum';
import {
    OfficerTypeEnumFromJSON,
    OfficerTypeEnumFromJSONTyped,
    OfficerTypeEnumToJSON,
} from './OfficerTypeEnum';

/**
 * 
 * @export
 * @interface PatchedOfficer
 */
export interface PatchedOfficer {
    /**
     * 
     * @type {number}
     * @memberof PatchedOfficer
     */
    readonly id?: number;
    /**
     * Officer Title.
     * @type {string}
     * @memberof PatchedOfficer
     */
    title?: string;
    /**
     * (P) - Person. (C) - Corporation.
     * 
     * * `P` - Person
     * * `C` - Corporation
     * @type {OfficerTypeEnum}
     * @memberof PatchedOfficer
     */
    officerType?: OfficerTypeEnum;
    /**
     * Officer Name
     * @type {string}
     * @memberof PatchedOfficer
     */
    name?: string;
    /**
     * Officer First Name
     * @type {string}
     * @memberof PatchedOfficer
     */
    firstName?: string;
    /**
     * Officer Middle Name
     * @type {string}
     * @memberof PatchedOfficer
     */
    middleName?: string;
    /**
     * Officer Last Name
     * @type {string}
     * @memberof PatchedOfficer
     */
    lastName?: string;
    /**
     * Officer Address
     * @type {string}
     * @memberof PatchedOfficer
     */
    address1?: string;
    /**
     * Officer Address 2
     * @type {string}
     * @memberof PatchedOfficer
     */
    address2?: string;
    /**
     * Officer City
     * @type {string}
     * @memberof PatchedOfficer
     */
    city?: string;
    /**
     * Officer State
     * @type {string}
     * @memberof PatchedOfficer
     */
    state?: string;
    /**
     * Officer Zip+4
     * @type {string}
     * @memberof PatchedOfficer
     */
    zip?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedOfficer
     */
    corporation?: number | null;
}



/**
 * Check if a given object implements the PatchedOfficer interface.
 */
export function instanceOfPatchedOfficer(value: object): value is PatchedOfficer {
    return true;
}

export function PatchedOfficerFromJSON(json: any): PatchedOfficer {
    return PatchedOfficerFromJSONTyped(json, false);
}

export function PatchedOfficerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedOfficer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'officerType': json['officer_type'] == null ? undefined : OfficerTypeEnumFromJSON(json['officer_type']),
        'name': json['name'] == null ? undefined : json['name'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'address1': json['address_1'] == null ? undefined : json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'corporation': json['corporation'] == null ? undefined : json['corporation'],
    };
}

export function PatchedOfficerToJSON(value?: Omit<PatchedOfficer, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'officer_type': OfficerTypeEnumToJSON(value['officerType']),
        'name': value['name'],
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'corporation': value['corporation'],
    };
}

